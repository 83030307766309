var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cadastro-segurado" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb mb-6" },
        [
          _c("breadcrumb", {
            attrs: {
              title: "Parametrização",
              actualPage: _vm.actualPage,
              previousPage: _vm.previousPage.router,
              previousPageTitle: _vm.previousPage.title,
            },
          }),
        ],
        1
      ),
      _c("router-view", {
        attrs: {
          permissions: _vm.permissions,
          getPermissionsCompleted: _vm.getPermissionsCompleted,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }