var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-tabs", attrs: { id: "horizontal-tabs" } },
    [
      _c(
        "div",
        { staticClass: "tab" },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "button",
            {
              key: tab.index,
              staticClass: "tabLinks cursor-pointer font-medium w-auto",
              class: { active: _vm.tabActive == tab.index },
              on: {
                click: function ($event) {
                  return _vm.updateTab(tab)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(tab.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm.isRouterContent
        ? _c(
            "div",
            { staticClass: "tabContent active" },
            [_c("router-view")],
            1
          )
        : _vm._l(_vm.tabs, function (item) {
            return _c(
              "div",
              {
                key: `${item.index}`,
                staticClass: "tabContent",
                class: { active: item.index == _vm.tabActive },
              },
              [_vm._t(`tab-content-${item.index}`)],
              2
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }