<template>
  <div class="new-tabs" id="horizontal-tabs">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        :class="{ active: tabActive == tab.index }"
        @click="updateTab(tab)"
        v-for="tab of tabs"
        :key="tab.index"
      >
        {{ tab.name }}
      </button>
    </div>

    <div class="tabContent active" v-if="isRouterContent">
      <router-view></router-view>
    </div>

    <template v-else>
      <div
        v-for="item of tabs"
        :key="`${item.index}`"
        class="tabContent"
        :class="{ active: item.index == tabActive }"
      >
        <slot :name="`tab-content-${item.index}`"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, require: true },
    isManyContents: { type: Boolean, default: true },
    isRouterContent: { type: Boolean, default: false }
  },
  data() {
    return { tabActive: null };
  },
  methods: {
    updateTab(tab) {
      if (this.isRouterContent) {
        if (tab.router instanceof Array)
          this.$router.push({ name: tab.router[0] });
        else this.$router.push({ name: tab.router });
      }

      this.tabActive = tab.index;
    }
  },
  mounted() {
    this.tabActive = 0;
  }
};
</script>

<style lang="scss">
#horizontal-tabs {
  .tab {
    overflow: hidden;
    background-color: white;
  }

  /* Style the buttons inside the tab */
  .tab button {
    background-color: white;
    float: left;
    border: none;
    border-bottom: 2px solid #d9d9d9;
    outline: none;
    padding: 10px 14px;
    transition: 0.3s;
    font-size: inherit;
    color: #848484;
    border-radius: 0 !important;
    font-family: inherit;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    color: rgba(var(--vs-warning), 1);
    border-bottom: 2px solid rgba(var(--vs-warning), 1);
  }

  /* Style the tab content */
  .tabContent {
    display: none;
    padding: 6px 12px;
    border: none;
    border-top: 2px solid #d9d9d9;
    margin-top: -2px;
  }

  .tabContent.active {
    display: block;
  }
}
</style>
